// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type CommandType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    deveui?: string;
    datetime?: Date;
    command?: string;
    status?: string;
    request?: string;
    response?: string;
};

@Injectable()
export class CommandService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(params?): Observable<CommandType[]> {
        return this.http.get('/api/commnds/', {params: params}) as Observable<CommandType[]>;
    }
    get(command: CommandType): Observable<CommandType> {
        return this.http.get(`/api/commnds/${command.id || command._id}`) as Observable<CommandType>;
    }
    create(command: CommandType) {
        return this.http.post('/api/commnds/', command);
    }
    update(command: CommandType, path) {
        return this.http.patch('/api/commnds/' + command._id, path) as Observable<CommandType>;
    }
    remove(command) {
        return this.http.delete(`/api/commnds/${command.id || command._id}`)
            .pipe(map(() => command));
    }
}
