import { Component } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

// @flow
interface User {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}

@Component({
	selector: 'settings',
	template: require('./settings.html'),
})
export class SettingsComponent {
	user: User = {
		oldPassword: '',
		newPassword: '',
		confirmPassword: ''
	};
	errors = {other: undefined};
	message = '';
	submitted = false;
	AuthService;
	ReCaptchaV3Service;

	static parameters = [AuthService, ReCaptchaV3Service];
	constructor(_AuthService_: AuthService, reCaptchaV3Service: ReCaptchaV3Service) {
		this.AuthService        = _AuthService_;
		this.ReCaptchaV3Service = reCaptchaV3Service;
	}

	executeReCaptcha() {
		this.ReCaptchaV3Service.execute('changePassword')
			.subscribe((token) => this.changePassword(token));
	}

	changePassword(response: string) {

		this.submitted = true;

		return this.AuthService.changePassword(this.user.oldPassword, this.user.newPassword, response)
			.then(() => {
				this.message = 'Пароль успешно изменен.';
			})
			.catch(() => {
				// form.password.$setValidity('mongoose', false);
				this.errors.other = 'Некорректный пароль';
				this.message = '';
			});
	}
}
