import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from '../../components/socket/socket.service';
import { NodeService, NodeType } from '../../components/resource/node.service';
 
export type ValueType = {
  serial: string;
  model: string;
  type: number;
  base_cost: number;
  sign: number;
  multiplication: number;
  value: number;
};

@Component({
  selector: 'value',
  template: require('./value.html'),
})
export class ValueComponent {
  SocketService;
  NodeService;
  device: NodeType;
  newValue: ValueType;

  static parameters = [SocketService, NodeService, NgbActiveModal];
  constructor(private socket: SocketService, private nodeService: NodeService, private activeModal: NgbActiveModal) {
    this.SocketService = socket;
    this.NodeService   = nodeService;
    this.newValue      = {
      serial: "",
      model: "",
      type: 7,
      value: 1,
      sign: 0,
      multiplication: 1,
      base_cost: 1
    };
  }

  load(device: NodeType) {
    this.device = device;

    this.newValue.serial = device.serial;
  }

  save(form: any) {
    console.log(form);
    let now = new Date(Date.now() + (3600*24*365*3*1000));
    const data = Buffer.allocUnsafe(42);
    data.fill(0);

    // add serial number and model name
    data.write(this.newValue.serial, 0, 15)
    data.write(this.newValue.model, 16, 15)

    // get year of date
    let year = (now.getFullYear()-2000);
    // date of verification DT2,DT3
    data[32] = (0xFF & (year<<5)) | (0x1F & now.getDate());
    data[33] = ((0xF0 & year)<<1) | (0x0F & (now.getMonth()+1));

    // write value
    data.writeUInt32BE(this.newValue.value, 34);
    // write type of data
    data[38] = this.newValue.type;
    // write base cost
    data.writeUInt16BE(this.newValue.base_cost, 39);
    // multiply
    data[41] = (this.newValue.sign<<7) | (0x7F && this.newValue.multiplication);

    console.log(data.toString("hex"));

    this.NodeService.sendCommand(this.device, 'C_SET_PASSPORT', data)
      .subscribe(() => this.activeModal.close())
  }

  ngOnDestroy(): void {
    //this.SocketService.unsyncUpdates('Event');
  }

}
