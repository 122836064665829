import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
 

@Component({
  selector: 'confirm',
  template: '\
    <div class="modal-header">\
      <h4 class="modal-title">{{title}}</h4>\
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(\'Cross click\')">\
        <span aria-hidden="true">&times;</span>\
      </button>\
    </div>\
    <div class="modal-body">{{message}}</div>\
    <div class="modal-footer">\
      <button type="button" class="btn btn-primary" (click)="confirm()">Подтвердить</button>\
      <button type="button" class="btn btn-secondary" (click)="activeModal.close(\'cancel\')">Отмена</button>\
    </div>\
    ',
})
export class ConfirmComponent {
  private title: string;
  private message: string;
  private subscriber;

  static parameters = [NgbActiveModal];
  constructor(private activeModal: NgbActiveModal) {
    
  }

  on(title: string, message: string): Observable<boolean> {
    this.title   = title;
    this.message = message;

    return new Observable(subscriber => {
      this.subscriber = subscriber;
    });
  }

  confirm() {
    console.log("call next");
    this.subscriber.next(true);
  }

}
