// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type StatType = {
  _id?: number;
  nodeId: number;
  chan: number;
  freq: number;
  lsnr: number;
  rfch: number;
  rssi: number;
  size: number;
  stat: number;
  tmms: number;
  tmst: number;
  
  codr: string;
  datr: string;

  time: Date;
};

@Injectable()
export class StatService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(params?): Observable<StatType[]> {
        return this.http.get('/api/stats/', {params: params}) as Observable<StatType[]>;
    }
    get(stat: StatType): Observable<StatType> {
        return this.http.get(`/api/stats/${stat._id}`) as Observable<StatType>;
    }
    create(stat: StatType) {
        return this.http.post('/api/stats/', stat);
    }
    update(stat: StatType, path) {
        return this.http.patch('/api/stats/' + stat._id, path) as Observable<StatType>;
    }
    remove(stat) {
        return this.http.delete(`/api/stats/${stat._id}`)
            .pipe(map(() => stat));
    }
}
