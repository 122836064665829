import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValueService } from '../../components/resource/value.service';
import { SocketService } from '../../components/socket/socket.service';
import { NodeType } from '../../components/resource/node.service';
 

@Component({
	selector: 'archive',
	template: require('./archive.html'),
})
export class ArchiveComponent {
	ValueService;
	SocketService;
	device: NodeType;
	archives = [];

	static parameters = [ValueService, SocketService, NgbActiveModal];
	constructor(private valueService: ValueService, private socket: SocketService, private activeModal: NgbActiveModal) {
		this.ValueService = valueService;
		this.SocketService = socket;
	}

	load(device: NodeType) {
		this.device = device;

		this.ValueService.query({deveui: this.device.deveui})
		  .subscribe(archives => {
				this.archives = archives;
				this.SocketService.syncUpdates('value', this.archives);
		  });
	}

	getDateWithOffset(date: string) {
		let dt = Date.parse(date);

		if(isNaN(dt)) {
			return null;
		}

		console.log(dt);
		return new Date(dt + (this.device.offset_time * 1000));
	}

	ngOnDestroy(): void {
		this.SocketService.unsyncUpdates('value');
	}

}
