import { Component, Input, Output, OnChanges, SimpleChange, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination',
  template: '\
  <nav aria-label="Постраничная навигация">\
  <ul class="pagination">\
    <li class="page-item" [ngClass]="{\'disabled\': page == 1}">\
      <a class="page-link" href="javascript:;" aria-label="Previous" (click)="onPrevPage()">\
        <span aria-hidden="true">&laquo;</span>\
      </a>\
    </li>\
    <li *ngFor="let i of list" class="page-item" [ngClass]="{\'disabled\': page == i}"><a class="page-link" href="javascript:;" (click)="onPage(i)">{{i}}</a></li>\
    <li class="page-item" [ngClass]="{\'disabled\': page == pages}">\
      <a class="page-link" href="javascript:;" aria-label="Next" (click)="onNextPage()">\
        <span aria-hidden="true">&raquo;</span>\
      </a>\
    </li>\
  </ul>\
</nav>',
})
export class PaginationComponent implements OnChanges {

  @Input() page: number = 1;
  @Input() pages: number = 1;
  @Input() pagesOnView: number = 5;
  @Output() onSwitchPage: EventEmitter<number> = new EventEmitter<number>();

  list: Array<number> = [];

  static parameters = [];
  constructor() {

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.list = [];
    let start = 1;

    if(this.pages > this.pagesOnView){
      start = this.page - Math.floor((this.pagesOnView/2));
    }

    for(let i = 1; i <= this.pages; i++) {
      if(this.pagesOnView == 0){
        this.list.push(i);
      } else {
        if(i >= start && i <= (start + this.pagesOnView)){
          this.list.push(i);
        }
      }
    }
  }

  onPage(page) {
    console.log(page, this.onSwitchPage);
    this.onSwitchPage.emit(page);
  }

  onPrevPage() {
    if(this.page > 1){
      this.page--;

      this.onSwitchPage.emit(this.page);
    }
  }

  onNextPage() {
    if(this.page < this.pages){
      this.page++;

      this.onSwitchPage.emit(this.page);
    }
  }
}
