import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StatService } from '../../components/resource/stat.service';
import { SocketService } from '../../components/socket/socket.service';
import { NodeType } from '../../components/resource/node.service';
 

@Component({
	selector: 'stat',
	template: require('./stats.html'),
})
export class StatsComponent {
	StatService;
	SocketService;
	device: NodeType;
	stats = [];

	static parameters = [StatService, SocketService, NgbActiveModal];
	constructor(private statService: StatService, private socket: SocketService, private activeModal: NgbActiveModal) {
		this.StatService = statService;
		this.SocketService = socket;
	}

	load(device: NodeType) {
		this.device = device;

		this.StatService.query({nodeId: this.device._id})
		  .subscribe(stats => {
				this.stats = stats;
				this.SocketService.syncUpdates('stat', this.stats);
		  });
	}


	ngOnDestroy(): void {
		this.SocketService.unsyncUpdates('stat');
	}

}
