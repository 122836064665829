// @flow
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

interface User {
	name: string;
	phone: string;
	password: string;
}

@Component({
	selector: 'login',
	template: require('./login.html'),
})
export class LoginComponent {
	user: User = {
		name: '',
		phone: '',
		password: '',
	};
	errors = {login: undefined};
	submitted = false;
	AuthService;
	Router;
	form;
  ReCaptchaV3Service;

	static parameters = [AuthService, Router, ReCaptchaV3Service];
	constructor(_AuthService_: AuthService, router: Router, reCaptchaV3Service: ReCaptchaV3Service) {
		this.AuthService        = _AuthService_;
		this.Router             = router;
    this.ReCaptchaV3Service = reCaptchaV3Service;
	}

  executeReCaptcha() {
    this.ReCaptchaV3Service.execute('login')
      .subscribe((token) => this.login(token));
  }

	login(response: string) {
		return this.AuthService.login({
		  phone: this.user.phone,
		  password: this.user.password,
		  token: response
		})
	  .then(() => {
  		// Logged in, redirect to home
  		this.Router.navigateByUrl('/home');
	  })
	  .catch(err => {
			console.log(err);
		  this.errors.login = err.message;
	  });
	}
}
