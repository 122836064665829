// @flow
import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

interface User {
	name: string;
	phone: string;
	password: string;
}

@Component({
	selector: 'signup',
	template: require('./signup.html'),
})
export class SignupComponent {
	user: User = {
		name: '',
		phone: '',
		password: ''
	};
	errors = {};
	submitted = false;
	AuthService;
	Router;
	ReCaptchaV3Service;

	static parameters = [AuthService, Router, ReCaptchaV3Service];
	constructor(_AuthService_: AuthService, router: Router, reCaptchaV3Service: ReCaptchaV3Service) {
		this.AuthService = _AuthService_;
		this.Router      = router;
		this.ReCaptchaV3Service = reCaptchaV3Service;
  }

  executeReCaptcha() {
		this.ReCaptchaV3Service.execute('registerUser')
			.subscribe((token) => this.register(token));
  }

	register(response: string) {

		this.submitted = true;

		return this.AuthService.createUser({
			name: this.user.name,
			phone: this.user.phone,
			password: this.user.password,
	  		token: response
		})
			.then(() => {
				// Account created, redirect to home
				this.Router.navigateByUrl('/home');
			})
			.catch(err => {
				this.errors = {};

				// Update validity of form fields that match the sequelize errors
				if(err.name) {
					err.fields.forEach(field => {
						this.errors[field] = err.message;
					});
				}

				this.submitted = false;
			});
	}
}
