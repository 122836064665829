import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fromlist',
  template: require('./from-list.html'),
})
export class FromListComponent implements OnInit {

  @Input() selected: string;
  @Input() field: string;
  @Input() title: string;
  @Input() list: Array<Object>;

  value: string;
  
  static parameters = [];
  constructor() {
    
  }

  ngOnInit() {
    (this.list || []).forEach(item => {
      if(item[this.field] == this.selected){
        this.value = item[this.title];
      }
    });
  }

}

