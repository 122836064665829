// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type ValueType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    deveui?: string;
    datetime?: Date;
    value?: number;
    created?: Date;
};

@Injectable()
export class ValueService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(params?): Observable<ValueType[]> {
        return this.http.get('/api/values/', {params: params}) as Observable<ValueType[]>;
    }
    get(value: ValueType): Observable<ValueType> {
        return this.http.get(`/api/values/${value.id || value._id}`) as Observable<ValueType>;
    }
    create(value: ValueType) {
        return this.http.post('/api/values/', value);
    }
    update(value: ValueType, path) {
        return this.http.patch('/api/values/' + value._id, path) as Observable<ValueType>;
    }
    remove(value) {
        return this.http.delete(`/api/values/${value.id || value._id}`)
            .pipe(map(() => value));
    }
}
