import { Component } from '@angular/core';

@Component({
	selector: 'admin',
	template: require('./admin.html'),
	styles: [require('./admin.css')],
})
export class AdminComponent {

	static parameters = [];
	constructor() {
		
	}
}
