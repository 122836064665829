// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type InitialType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    deveui?: string;
    datetime?: Date;
    value?: number;
    created?: Date;
};

@Injectable()
export class InitialService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(params?): Observable<InitialType[]> {
        return this.http.get('/api/initials/', {params: params}) as Observable<InitialType[]>;
    }
    get(initial: InitialType): Observable<InitialType> {
        return this.http.get(`/api/initials/${initial.id || initial._id}`) as Observable<InitialType>;
    }
    create(initial: InitialType) {
        return this.http.post('/api/initials/', initial);
    }
    update(initial: InitialType, path) {
        return this.http.patch('/api/initials/' + initial._id, path) as Observable<InitialType>;
    }
    remove(initial) {
        return this.http.delete(`/api/initials/${initial.id || initial._id}`)
            .pipe(map(() => initial));
    }
}
