import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeType } from '../../../../components/resource/node.service';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
  selector: 'upload',
  template: '\
    <div class="modal-header">\
      <h4 class="modal-title">Загрузка файла с приборами</h4>\
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(\'Cross click\')">\
        <span aria-hidden="true">&times;</span>\
      </button>\
    </div>\
    <div class="modal-body">\
      <input type="file" (change)="onFileChange($event)" multiple="false" accept=".xlsx" />\
    </div>\
    <div class="modal-footer">\
      <button type="button" class="btn btn-secondary" (click)="activeModal.close(\'cancel\')">Отменить</button>\
    </div>',
})
export class UploadComponent {
  private subscriber;

  static parameters = [NgbActiveModal];
  constructor(private activeModal: NgbActiveModal) {

  }

  onRead() {
    return new Observable(subscriber => {
      this.subscriber = subscriber;
    });
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1){
      throw new Error('Cannot use multiple files');
    }

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      let data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 0}));
      //data.splice(0, 1);
      let devices = [];
      //let columns = data.splice(0, 1).shift();
      //console.log(columns);

      data.forEach((row: [any]) => {
        let device:NodeType = {
          offset_time: 0, 
          offset_value: 0, 
          request_current_time: false,
          disabled: false,
          batVoltage: 0,
        };

        for(let name in row){
          let value:string = row[name];
          if(name == 'deveui' || name == 'joineui'){
            value = value.replace(/:/g, '');
          }

          device[name] = value;
        }

        // columns.forEach((name: string, index: number) => {
        //   let value: string = row[index];

        //   if(name == 'deveui' || name == 'joineui'){
        //     value = value.replace(/:/g, '');
        //   }

        //   device[name] = value;
        // });

        devices.push(device);
      })

      this.subscriber.next(devices);
    };

    reader.readAsBinaryString(target.files[0]);
  }
}
