import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InitialService, InitialType } from '../../components/resource/initial.service';
import { SocketService } from '../../components/socket/socket.service';
import { NodeType } from '../../components/resource/node.service';
 

@Component({
	selector: 'initial',
	template: require('./initial.html'),
})
export class InitialComponent {
	device: NodeType;
	initial: InitialType;
	items = [];

	static parameters = [InitialService, SocketService, NgbActiveModal];
	constructor(private initialService: InitialService, private socketService: SocketService, private activeModal: NgbActiveModal) {
		
	}

	save() {
		this.initialService.create(this.initial)
			.subscribe(initial => console.log(initial));
	}

	remove(item) {
		this.initialService.remove(item)
			.subscribe(item => console.log(item));
	}

	load(device: NodeType) {
		this.device = device;
		this.initial = {
			deveui: device.deveui,
			created: new Date(),
			datetime: new Date(),
			value: 0
		};

		this.initialService.query({deveui: this.device.deveui})
		  .subscribe(items => {
				this.items = items;
				this.socketService.syncUpdates('initial', this.items);
		  });
	}

	ngOnDestroy(): void {
		this.socketService.unsyncUpdates('initial');
	}

}
