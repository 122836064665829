// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type EventType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    deveui?: string;
    devaddr?: string;
    datetime?: Date;
    event?: string;
};

@Injectable()
export class EventService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(params?): Observable<EventType[]> {
        return this.http.get('/api/events/', {params: params}) as Observable<EventType[]>;
    }
    get(event: EventType): Observable<EventType> {
        return this.http.get(`/api/events/${event.id || event._id}`) as Observable<EventType>;
    }
    create(event: EventType) {
        return this.http.post('/api/events/', event);
    }
    update(event: EventType, path) {
        return this.http.patch('/api/events/' + event._id, path) as Observable<EventType>;
    }
    remove(event) {
        return this.http.delete(`/api/events/${event.id || event._id}`)
            .pipe(map(() => event));
    }
}
