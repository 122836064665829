/*eslint no-process-env:0*/

export const env = process.env.NODE_ENV;
export const port = process.env.PORT || 9000;
// List of user roles
export const userRoles = ['guest', 'user', 'manager', 'admin'];

export const googleReCaptchaKey = '6LeC-zMaAAAAAOhVjlGnKWsOYbm_V5-5aJ2OO52T';

export default {
    env,
    port,
    userRoles,
};


// 'use strict';
// /*eslint no-process-env:0*/

// module.exports.default = {
//     env: process.env.NODE_ENV,
//     port: process.env.PORT || 9000,
//     // List of user roles
//     userRoles: ['guest', 'user', 'admin'],
//     googleReCaptchaKey: '6LeC-zMaAAAAAOhVjlGnKWsOYbm_V5-5aJ2OO52T'
// };
