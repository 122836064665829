import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';


import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../components/auth/auth-guard.service';
import { AuthModule } from '../../components/auth/auth.module';

import { CommonModule } from '@angular/common';

import { MainComponent } from './main.component';
import { SocketService } from '../../components/socket/socket.service';
//import { DeviceUplinksComponent } from '../../components/device-uplinks/device-uplinks.component';

import { UserService } from '../../components/auth/user.service';
import { DeviceService } from '../../components/resource/device.service';
import { DeviceTypeService } from '../../components/resource/device-type.service';
import { UplinkService } from '../../components/resource/uplink.service';
import { ValueService } from '../../components/resource/value.service';
import { InitialService } from '../../components/resource/initial.service';
import { StatService } from '../../components/resource/stat.service';
import { EventService } from '../../components/resource/event.service';
import { NodeService } from '../../components/resource/node.service';
import { CommandService } from '../../components/resource/command.service';
import { FromListComponent } from '../../components/from-list/from-list.component';
import { ArchiveComponent } from '../../components/archive/archive.component';
import { InitialComponent } from '../../components/initial/initial.component';
import { StatsComponent } from '../../components/stats/stats.component';
import { EventComponent } from '../../components/event/event.component';
import { ValueComponent } from '../../components/value/value.component';

import {DeviceComponent as DeviceFormComponent} from './device/device.component';

export const ROUTES: Routes = [{
    path: 'home', 
    component: MainComponent, 
    canActivate: [AuthGuard],
  }];


@NgModule({
  imports: [
    AuthModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    RouterModule.forChild(ROUTES),


    //DeviceUplinksComponent,
    //FromListComponent
  ],
  declarations: [
    MainComponent,
    //DeviceUplinksComponent,
    FromListComponent,
    ArchiveComponent,
    InitialComponent,
    StatsComponent,
    EventComponent,
    ValueComponent,
    DeviceFormComponent,
  ],
  providers: [
    SocketService,
    UserService,
    DeviceService,
    DeviceTypeService,
    UplinkService,
    ValueService,
    InitialService,
    StatService,
    EventService,
    NodeService,
    CommandService,
  ],
  exports: [
    MainComponent,
    //DeviceUplinksComponent,
    //FromListComponent,
  ],
  entryComponents: [
    FromListComponent,
    DeviceFormComponent,
    ArchiveComponent,
    InitialComponent,
    StatsComponent,
    EventComponent,
    ValueComponent,
  ]
})
export class MainModule {}
