import {Pipe, PipeTransform } from '@angular/core';
import { NodeType } from '../resource/node.service';


@Pipe({name: 'orderBy', pure: false})
export class OrderByPipe implements PipeTransform {
  transform(items: NodeType[], fields?: Object): NodeType[] {
    if(fields) {
      return Array.prototype.slice.call(items).sort((n1: NodeType, n2: NodeType) => {
        for(let name in fields){

          if(n1[name] === undefined || n1[name] === null){
            n1[name] = "";
          }

          if(n2[name] === undefined || n2[name] === null){
            n2[name] = "";
          }

          // same value, check next
          if(n1[name] === n2[name]){
            continue;
          }

          return n1[name] > n2[name] ? fields[name] : fields[name] * -1;
        }

        return -1;
      });
    }

    return items;
  }
}
