// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type DeviceType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    userId?: number;
    serial?: string;
    deveui?: string;
    name?: string;
    lastValue?: number;
    token?:string;
};

@Injectable()
export class DeviceService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(): Observable<DeviceType[]> {
        return this.http.get('/api/devices/') as Observable<DeviceType[]>;
    }
    get(device: DeviceType): Observable<DeviceType> {
        return this.http.get(`/api/devices/${device.id || device._id}`) as Observable<DeviceType>;
    }
    create(device: DeviceType) {
        return this.http.post('/api/devices/', device);
    }
    update(device: DeviceType, path) {
        return this.http.patch('/api/devices/' + device._id, path) as Observable<DeviceType>;
    }
    remove(device) {
        return this.http.delete(`/api/devices/${device.id || device._id}`)
            .pipe(map(() => device));
    }
}

