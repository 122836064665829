/**
 * The Util service is for thin, globally reusable, utility functions
 */

import { isFunction, } from 'lodash';
import { noop, } from 'lodash-es';
import { PipeTransform, Pipe } from '@angular/core';

/**
 * Return a callback or noop function
 *
 * @param  {Function|*} cb - a 'potential' function
 * @return {Function}
 */
export function safeCb(cb) {
    return isFunction(cb) ? cb : noop;
}

/**
 * Parse a given url with the use of an anchor element
 *
 * @param  {String} url - the url to parse
 * @return {Object}     - the parsed url, anchor element
 */
export function urlParse(url) {
    var a = document.createElement('a');
    a.href = url;

    // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
    if (a.host === '') {
        a.href = a.href;
    }

    return a;
}

/**
 * Test whether or not a given url is same origin
 *
 * @param  {String}           url       - url to test
 * @param  {String|String[]}  [origins] - additional origins to test against
 * @return {Boolean}                    - true if url is same origin
 */
export function isSameOrigin(url, origins) {
    url = urlParse(url);
    origins = (origins && [].concat(origins)) || [];
    origins = origins.map(urlParse);
    origins.push(window.location);
    origins = origins.filter(function(o) {
        let hostnameCheck = url.hostname === o.hostname;
        let protocolCheck = url.protocol === o.protocol;
        // 2nd part of the special treatment for IE fix (see above):
        // This part is when using well-known ports 80 or 443 with IE,
        // when window.location.port==='' instead of the real port number.
        // Probably the same cause as this IE bug: https://goo.gl/J9hRta
        let portCheck = url.port === o.port || (o.port === '' && (url.port === '80' || url.port === '443'));
        return hostnameCheck && protocolCheck && portCheck;
    });
    return origins.length >= 1;
}


export function CompileHtml($compile) {
  'ngInject';
  return {
    restrict: 'AE',
    link : function (scope, element, attr) {
      attr.$observe('ngBindHtml',function(){
        if(attr.ngBindHtml){
          $compile(element[0].children)(scope);
        }
      })
    }
  };
}

@Pipe({
    name: 'callback',
    pure: false
})
export class CallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}

@Pipe({
    name: 'paginate',
    pure: false
})
export class PaginationPipe implements PipeTransform {
  transform(items: any[], paginate: {pageNumber: number, itemsPerPage: number}): any {
    if (!items || !paginate) {
      return items;
    }

    let pageIndex = (paginate.pageNumber || 1) - 1;
    let length    = items.length;
    let pages     = Math.ceil(length / paginate.itemsPerPage);
    let offset    = Math.floor(pageIndex * paginate.itemsPerPage);
    let limit     = Math.floor(paginate.itemsPerPage);

    return items.slice(offset, offset + limit);
  }
}
