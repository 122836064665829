// @flow
import { Component, OnDestroy,Pipe, PipeTransform } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../components/auth/user.service';
import { DeviceService } from '../../../components/resource/device.service';
import { NodeService, NodeType } from '../../../components/resource/node.service';
import { AlertType } from '../../../components/resource/alert.service';
import { CommandService, CommandType } from '../../../components/resource/command.service';
import { ArchiveComponent } from '../../../components/archive/archive.component';
import { InitialComponent } from '../../../components/initial/initial.component';
import { StatsComponent } from '../../../components/stats/stats.component';
import { EventComponent } from '../../../components/event/event.component';
import { ValueComponent } from '../../../components/value/value.component';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { DeviceComponent as DeviceFormComponent } from './form/device.component';
import { SocketService } from '../../../components/socket/socket.service';
import { UploadComponent } from './upload/upload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { observe, generate } from 'fast-json-patch';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { OrderByPipe } from '../../../components/pipes/order-by';

const FIAS_ADDRESS_URL = '/api/nodes/fias/';

type House = {
  _id: number;
  name: string;
  full_address: string;
  fias_object_id: number;
}

type InProgress = {
  saving: boolean;
  error?: string;
  success: boolean;
}

type Filter = {
  text: string;
  lastEventLessThanDays?: number;
  noDataMoreThanDays?: number;
  disabled?: boolean;
  attached?: boolean;
};

type MapInProgress = Record<string, InProgress>;

@Component({
  selector: 'device',
  template: require('./device.html'),
  styles: [require('./device.css')],
})
export class DeviceComponent {
  Router;
  UserService;
  DeviceService;
  NodeService;
  ModalService;
  SocketService;
  ActivatedRoute;

  devices = [];
  filtered = [];
  addresses:any = {};
  alerts:Array<AlertType> = [];
  newNode:NodeType = {
    deveui: "",
    serial: "",
    profile: "Watercounter",
    offset_time: 0, 
    offset_value: 0, 
    request_current_time: false,
    disabled: false,
  };
  inProgress: MapInProgress = {};
  page: number = 1;
  limit: number = 50;
  pages: number = 1;
  filter: Filter = {text: ""};
  interval: any = null;
  ordering = {
    address: 1, 
    flat: 1
  };
  
  static parameters = [HttpClient, OrderByPipe, Router, ActivatedRoute, UserService, DeviceService, NodeService, NgbModal, SocketService];
  constructor(private http: HttpClient, private orderByPipe: OrderByPipe, router: Router, activatedRoute: ActivatedRoute, userService: UserService, deviceService: DeviceService, nodeService: NodeService, modal: NgbModal, socket: SocketService) {
    this.Router        = router;
    this.UserService   = userService;
    this.DeviceService = deviceService;
    this.NodeService   = nodeService;
    this.ModalService  = modal;
    this.SocketService = socket;
    this.ActivatedRoute = activatedRoute;
  }

  ngOnDestroy() {
    this.SocketService.unsyncUpdates('node');
    this.SocketService.unsyncUpdates('Event');
    this.SocketService.unsyncUpdates('value');
  }

  ngOnInit() {
    this.ActivatedRoute.queryParams.subscribe(params => {
      this.filter.text = params['search'] || "";

      if(this.filter.text.length > 0){
        this.filter.disabled = true;
      }
    });

    // this.alerts.push({
    //   type: "info",
    //   text: "test long message, with very long descripts so it is so long, why it's so long maybe something wrong",
    //   deveui: "0497900100FF7CF7",
    // });

    this.NodeService.query().subscribe(devices => {
      this.devices = devices; 
      
      // devices.sort((d1: NodeType, d2: NodeType) => {
      //   return d1._id > d2._id ? -1 : 1;
      // })

      let ids: number[] = [];
      this.devices.forEach(device => {
        if(device.fias_id && ids.indexOf(device.fias_id) == -1) {
          ids.push(device.fias_id);
          this.loadAddress(device.fias_id);
        }
      })

      this.SocketService.syncUpdates('node', this.devices, (eventName, node) => this.updateDeviceList());
      this.SocketService.syncUpdates('Event', [], (eventName, event) => {
        this.alerts.push({
          type: 'info',
          text: `Поступило событие "${event.event}" от прибора ${event.serial || event.deveui}`,
          deveui: event.deveui,
        });
      });

      this.SocketService.syncUpdates('value', [], (eventName, value) => {
        let found: boolean = false;
        (this.alerts || []).forEach((alert: any) => {
          if (alert.id == 'value-' + value.deveui){
            found = true;

            alert.text = `Поступили данные от прибора "${value.deveui}" за ${value.datetime} значение: ${value.value}`;
          }
        })

        if( ! found){
          this.alerts.push({
            id: 'value-' + value.deveui,
            deveui: value.deveui,
            type: 'info',
            text: `Поступили данные от прибора "${value.deveui}" за ${value.datetime} значение: ${value.value}`
          });
        }
      });

      this.SocketService.syncUpdates('command', [], (eventName, command) => {
        let found: boolean = false;
        (this.alerts || []).forEach((alert: any) => {
          if (alert.id == 'command-' + command._id){
            found = true;

            alert.text = `Поступили команда для прибора "${command.deveui}" №${command._id} команда[${command.command}]: (${command.status})`;
          }
        })

        if( ! found){
          this.alerts.push({
            id: 'command-'  + command._id,
            type: 'info',
            text: `Поступили команда для прибора "${command.deveui}" №${command._id} команда[${command.command}]: (${command.status})`,
            deveui: command.deveui,
          });
        }
      });

      this.updateDeviceList();
    })
  }

  updateOrder(ordering) {
    this.ordering = ordering;
  }

  loadAddress(fias_id) {
    this.http.get<House>(FIAS_ADDRESS_URL + fias_id)
      .subscribe(house => {
        this.devices.forEach(device => {
          if(device.fias_id == fias_id) {
            device.address = house.full_address;
          }
        })
      });
  }

  getContrastColor(red, green, blue) {
    let d = 0;

    // Counting the perceptive luminance - human eye favors green color... 
    let luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    if (luminance > 0.5){
      d = 0; // bright colors - black font
    } else {
      d = 255; // dark colors - white font
    }

    return {
      red:   d, 
      green: d, 
      blue:  d
    };
  }

  colorGradient(fadeFraction) {
    
    let color1 = {red: 19, green: 233, blue: 19};
    let color2 = {red: 255, green: 255, blue: 0};

    let fade = fadeFraction * 2;

    //Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = {red: 255, green: 255, blue: 0};
      color2 = {red: 255, green: 0, blue: 0};
    }

    let diffRed = color2.red - color1.red;
    let diffGreen = color2.green - color1.green;
    let diffBlue = color2.blue - color1.blue;

    var gradient = {
      red: Math.floor(color1.red + (diffRed * fade)),
      green: Math.floor(color1.green + (diffGreen * fade)),
      blue: Math.floor(color1.blue + (diffBlue * fade)),
    };

    let color = this.getContrastColor(gradient.red, gradient.green, gradient.blue);

    return {
      background: 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')',
      color: 'rgb(' + color.red + ',' + color.green + ',' + color.blue + ')',
    };
}

  getColor(device, field) {
    if( ! device[field]){
      return {'background': 'red', 'color': '#fff'};
    }

    let diff = (Date.now() - Date.parse(device[field])) / 1000;

    let percent = diff * 100 / 864000;

    if(percent > 100){
      percent = 100;
    }

    return this.colorGradient(percent/100);
  }

  updateDeviceList() {
    console.log("updateDeviceList()");
    // get words in search field
    let words: Array<string> = (this.filter.text || "").split(" ").filter(word => word.length > 0);
    this.filtered.length = 0;
    this.devices.forEach((device: NodeType) => {

      if( ! this.filter.disabled){
        if(device.disabled){
          return;
        }
      }

      if(this.filter.attached){
        if( ! device.address){
          return;
        }
      }

      if(this.filter.lastEventLessThanDays){
        if( ! device.dtime_last_event){
          return;
        }


        let diff = (Date.now() - Date.parse(device.dtime_last_event)) / (3600*24*1000);

        if (diff > this.filter.lastEventLessThanDays){
          return;
        }
      }

      if(this.filter.noDataMoreThanDays){
        if(device.dtime_last_request){
          let diff = (Date.now() - Date.parse(device.dtime_last_request)) / (3600*24*1000);

          if (diff <= this.filter.noDataMoreThanDays){
            return;
          }
        }
      }

      // allow all
      if(words.length > 0){
        // get all words
        let match = words.filter((word: string) => {
          // cast to lower case
          word = word.toLowerCase();

          if(('' + device.serial).toLowerCase().indexOf(word) != -1){
            this.filtered.push(device);
            return;
          }

          if(('' + device.deveui).toLowerCase().indexOf(word) != -1){
            this.filtered.push(device);
            return;
          }

          return;
        }).length == words.length;

        if( ! match){
          return;
        }
      }


      this.filtered.push(device);
    });

    this.pages = Math.ceil(this.filtered.length / this.limit);

    // console.log("piping...");
    // this.orderByPipe.transform(this.filtered, this.ordering);
  }

  onTypeFilter(x) {

    if(this.interval){
      clearTimeout(this.interval);
    }

    this.interval = setTimeout(() => this.updateDeviceList(), 1500);
  }

  onSwitchPage(page) {
    this.page = page;
  }

  editDevice(device) {
    let dialog     = this.ModalService.open(DeviceFormComponent);
    let observable = dialog.componentInstance.onSave(device);
    
    observable.subscribe(path => {
      return this.NodeService.update(device, path)
        .subscribe(updated => dialog.close());
    });

    dialog.result.then(() => {
      // after dismiss
      //observable.unsubscribe();
    });
  }

  createDevice() {
    let dialog     = this.ModalService.open(DeviceFormComponent);
    let observable = dialog.componentInstance.onSave(this.newNode);
    
    observable.subscribe(path => {
      return this.NodeService.create(this.newNode)
        .subscribe(created => {
          dialog.close();

          this.devices.push(created);
          this.newNode = {
            deveui: "",
            serial: "",
            profile: "Watercounter",
            offset_time: 0, 
            offset_value: 0, 
            request_current_time: false,
            disabled: false,
          };
        });
    });

    dialog.result.then(() => {
      // after dismiss
      //observable.unsubscribe();
    });
  }

  /*
  setProgress(device: NodeType) {
    this.inProgress[device.deveui] = {
      saving: true,
      error: null,
      success: false
    };
  }

  setSuccess(device: NodeType) {
    this.inProgress[device.deveui] = {
      saving: true,
      error: null,
      success: true
    };
  }

  setError(device: NodeType) {
    this.inProgress[device.deveui] = {
      saving: true,
      error: "Some error occurs",
      success: false
    };
  }
  */

  openArchive(device: NodeType) {
    let dialog = this.ModalService.open(ArchiveComponent, {size: 'lg', animation: true});
    dialog.componentInstance.load(device);
  }

  openInitial(device: NodeType) {
    let dialog = this.ModalService.open(InitialComponent, {size: 'lg', animation: true});
    dialog.componentInstance.load(device);
  }

  openStats(device: NodeType) {
    let dialog = this.ModalService.open(StatsComponent, {size: 'lg', animation: true});
    dialog.componentInstance.load(device);
  }

  openEvent(device: NodeType) {
    let dialog = this.ModalService.open(EventComponent, {size: 'lg', animation: true});
    dialog.componentInstance.load(device.deveui);
  }

  inputValue(device: NodeType) {
    let dialog = this.ModalService.open(ValueComponent, {size: 'sm', animation: true});
    dialog.componentInstance.load(device);
  }

  requestArchive(device: NodeType) {
    this.NodeService.sendCommand(device, 'C_GET_MBUS_METERING', "")
      .subscribe(() => console.log("Packet sended!"))
  }

  getError(device: NodeType): string {
    if( ! (device.deveui in this.inProgress)){
      return '';
    }

    return this.inProgress[device.deveui].error;
  }

  deleteDevice(device: NodeType) {
    let dialog     = this.ModalService.open(ConfirmComponent);
    let observable =  dialog.componentInstance.on("Подтвердение удаления!", "Вы действительно хотите удалить прибор " + device.deveui + "?");

    observable.subscribe((confirm: boolean) => {
      if(confirm){
        this.NodeService.remove(device).toPromise();
      }

      // closing form
      dialog.close();
    });
  }

  getClass(device: NodeType) {
    let classes = {
      success: false,
      error: false,
      processing: false,
    };

    if(device.deveui in this.inProgress){
      classes.error   = !!(this.inProgress[device.deveui].error);
      classes.success = this.inProgress[device.deveui].success;

      if( ! classes.error && ! classes.success){
        classes.processing = this.inProgress[device.deveui].saving;
      }
    }

    return classes;
  }

  uploadExcel() {
    let dialog     = this.ModalService.open(UploadComponent);
    let observable = dialog.componentInstance.onRead();

    observable.subscribe((devices: Array<NodeType>) => {
      // closing form
      dialog.close();

      // let's get start updating
      return Promise.all(devices.map((row: NodeType) => {
        let deveui: string = row.deveui;
        let device: NodeType = this.devices.filter((device: NodeType) => device.deveui == deveui).shift();

        this.inProgress[deveui] = {
          saving: true,
          error: null,
          success: false
        };

        if( ! device) {

          if( ! row.serial){
            // init error message
            this.inProgress[deveui].error = `Прибор с номером(deveui) ${deveui} не найден, необходимо ввести его серийный номер!`;
            // add error to the list of alerts
            this.alerts.push({
              type: 'danger',
              text: this.inProgress[deveui].error,
              deveui: deveui,
            });
          }

          // create new node
          return this.NodeService.create({
            deveui:  row.deveui,
            serial:  row.serial || "---",
            profile: this.newNode.profile,
            appkey:  row.appkey,
            appskey: row.appskey,
            nwkskey: row.nwkskey,
            devaddr: row.devaddr,
            disabled: false,
          }).toPromise()
            .catch(err => {
              this.inProgress[deveui].error = err.json().message;
            });

        }

        let observer = observe(device);
        let keys = Object.keys(row) as Array<keyof NodeType>;
        for(let field of keys){
          try {
            device[field] = row[field];
          } catch(err){
            console.error(err);
          }
        }

        let path = generate(observer);

        return this.NodeService.update(device, path).toPromise()
          .then(() => {
            this.inProgress[deveui].success = true;
          })
          .catch(err => {
            this.inProgress[deveui].error = err.json().message;
          });
      }))
    });

    dialog.result.then(() => {
      console.log(observable);
    });
  }
}
