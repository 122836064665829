// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type DeviceTypeType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    name?: string;
};

@Injectable()
export class DeviceTypeService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(): Observable<DeviceTypeType[]> {
        return this.http.get('/api/device-types/') as Observable<DeviceTypeType[]>;
    }
    get(deviceType: DeviceTypeType): Observable<DeviceTypeType> {
        return this.http.get(`/api/device-types/${deviceType.id || deviceType._id}`) as Observable<DeviceTypeType>;
    }
    create(deviceType: DeviceTypeType) {
        return this.http.post('/api/device-types/', deviceType);
    }
    update(deviceType: DeviceTypeType, path) {
        return this.http.patch('/api/device-types/' + deviceType._id, path) as Observable<DeviceTypeType>;
    }
    remove(deviceType) {
        return this.http.delete(`/api/device-types/${deviceType.id || deviceType._id}`)
            .pipe(map(() => deviceType));
    }
}
