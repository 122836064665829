import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceType } from '../../../components/resource/device.service';
import { Observable } from 'rxjs';
import { observe, generate } from 'fast-json-patch';

@Component({
  template: require('./device.html'),
})
export class DeviceComponent {
  private device: DeviceType;  
  private observer;
  private subscriber;

  static parameters = [NgbActiveModal];
  constructor(private activeModal: NgbActiveModal) {

  }

  onSave(device: DeviceType): Observable<DeviceType> {
    this.device = device;
    this.observer = observe(this.device);

    return new Observable(subscriber => {
      this.subscriber = subscriber;
    });
  }

  save(form) {
    if(form.invalid) return;

    this.subscriber.next(generate(this.observer));
  }
}
