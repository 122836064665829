// @flow
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../components/auth/user.service';
import { UserComponent as UserFormComponent } from './form/user.component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'user',
  template: require('./user.html'),
})
export class UserComponent {
  Router;
  UserService;
  ModalService;
  users = [];

  static parameters = [Router, UserService, NgbModal];
  constructor(router: Router, userService: UserService, modal: NgbModal) {
    this.Router = router;
    this.UserService = userService;
    this.ModalService = modal;
  }

  editUser(user) {
    let dialog     = this.ModalService.open(UserFormComponent);
    let observable = dialog.componentInstance.onSave(user);
    
    observable.subscribe(path => {
      return this.UserService.update(user, path)
        .subscribe(updated => dialog.close());
    });

    dialog.result.then(() => {
      console.log(observable);
      // after dismiss
      //observable.unsubscribe();
    });
  }

  ngOnInit() {
    this.UserService.query().subscribe(users => {
      this.users = users;
    })
  }
}
