import {
    NgModule,
    Injectable,
    ApplicationRef,
    Provider,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    removeNgStyles,
    createNewHosts,
    createInputTransfer,
} from '@angularclass/hmr';

import { RouterModule, Routes } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule }   from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule, ReCaptchaV3Service } from "ng-recaptcha";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { DirectivesModule } from '../components/directives.module';
import { TokenInterceptor } from '../components/auth/token.interceptor';
import { AuthService } from '../components/auth/auth.service';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';

export function tokenGetter() {
    return localStorage.getItem('id_token');
}

let providers: Provider[] = [
    ReCaptchaV3Service, {
    provide: RECAPTCHA_V3_SITE_KEY, 
    useValue: "6LeC-zMaAAAAAOhVjlGnKWsOYbm_V5-5aJ2OO52T"
}, CookieService, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
    deps: [ AuthService ]
}];

const appRoutes: Routes = [{ 
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
}];

@NgModule({
    providers,
    imports: [
        BrowserModule,

        RouterModule.forRoot(appRoutes, { enableTracing: process.env.NODE_ENV === 'development' }),
        NgbModule.forRoot(),
        FormsModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
            }
        }),
        RecaptchaModule,
        RecaptchaV3Module,

        NgxMaskModule.forRoot(),
        TypeaheadModule.forRoot(),

        MainModule,
        DirectivesModule,
        AccountModule,
        AdminModule,
    ],
    exports: [
        RecaptchaModule,
        RecaptchaV3Module
    ],
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    static parameters = [ApplicationRef];
    constructor(private appRef: ApplicationRef) {
        this.appRef = appRef;
    }

    hmrOnInit(store) {
        if (!store || !store.state) return;
        console.log('HMR store', store);
        console.log('store.state.data:', store.state.data);
        // inject AppStore here and update it
        // this.AppStore.update(store.state)
        if ('restoreInputValues' in store) {
            store.restoreInputValues();
        }
        // change detection
        this.appRef.tick();
        Reflect.deleteProperty(store, 'state');
        Reflect.deleteProperty(store, 'restoreInputValues');
    }

    hmrOnDestroy(store) {
        var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        // var appState = this.AppStore.get()
        store.state = {data: 'yolo'};
        // store.state = Object.assign({}, appState)
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    }

    hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        Reflect.deleteProperty(store, 'disposeOldHosts');
        // anything you need done the component is removed
    }
}
