import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { CookieService } from 'ngx-cookie-service';
import { safeCb } from '../util';
import { userRoles } from '../../app/app.constants';

// @flow
class User {
	_id = '';
	name = '';
	phone = '';
	role = '';
	$promise = undefined;
}

@Injectable()
export class AuthService {
	_currentUser: User = new User();
	@Output() currentUserChanged = new EventEmitter(true);
	userRoles = userRoles || [];
	UserService;
	observable = null;

	static parameters = [HttpClient, CookieService, UserService, Router];
	constructor(private http: HttpClient, private cookieService: CookieService, private userService: UserService, private router: Router) {
		this.http        = http;
		this.UserService = userService;
		this.router      = router;
	}

	/**
	 * Check if userRole is >= role
	 * @param {String} userRole - role of current user
	 * @param {String} role - role to check against
	 */
	static hasRole(userRole, role) {
		return userRoles.indexOf(userRole) >= userRoles.indexOf(role);
	}

	get currentUser() {
		return this._currentUser;
	}

	set currentUser(user) {
		this._currentUser = user;
		this.currentUserChanged.emit(user);
	}

	setCookie(name, value) {
		this.cookieService.set(name, value, undefined, undefined, undefined, undefined, "Lax");
	}

	/**
	 * Authenticate user and save token
	 *
	 * @param  {Object}   user     - login info
	 * @param  {Function} [callback] - function(error, user)
	 * @return {Promise}
	 */
	login({phone, password, token}, callback) {
		return this.http.post('/auth/local', {
			phone,
			password,
			token
		})
			.toPromise()
			.then((res: {token: string}) => {
				this.setCookie('id_token', res.token);
				return this.UserService.get().toPromise();
			})
			.then((user: User) => {
				this.currentUser = user;
				this.setCookie('user', JSON.stringify(user));
				safeCb(callback)(null, user);
				return user;
			})
			.catch(err => {
				this.logout();
				safeCb(callback)(err);
				return Promise.reject(err);
			});
	}

	/**
	 * Delete access token and user info
	 * @return {Promise}
	 */
	logout() {
		this.cookieService.delete('user');
		this.cookieService.delete('id_token');
		this.currentUser = new User();
		return Promise.resolve();
	}

	/**
	 * Create a new user
	 *
	 * @param  {Object}   user     - user info
	 * @param  {Function} callback - optional, function(error, user)
	 * @return {Promise}
	 */
	createUser(user, callback) {
		return this.UserService.create(user).toPromise()
			.then(data => {
				this.setCookie('id_token', data.token);
				return this.UserService.get().toPromise();
			})
			.then((_user: User) => {
				this.currentUser = _user;
				return safeCb(callback)(null, _user);
			})
			.catch(err => {
				this.logout();
				safeCb(callback)(err);
				return Promise.reject(err);
			});
	}

	/**
	 * Change password
	 *
	 * @param  {String}   oldPassword
	 * @param  {String}   newPassword
	 * @param  {Function} [callback] - function(error, user)
	 * @return {Promise}
	 */
	changePassword(oldPassword, newPassword, token, callback) {
		return this.UserService.changePassword({id: this.currentUser._id}, oldPassword, newPassword, token)
			.toPromise()
			.then(() => safeCb(callback)(null))
			.catch(err => safeCb(callback)(err));
	}

	/**
	 * Gets all available info on a user
	 *
	 * @param  {Function} [callback] - function(user)
	 * @return {Promise}
	 */
	getCurrentUser(callback?) {
		if( ! this.currentUser._id){

			if( ! this.observable){
				this.observable = this.UserService.get();
			}

			return this.observable.toPromise()
				.then(user => {
					this.currentUser = user;
					safeCb(callback)(this.currentUser);
					return this.currentUser;
				})
				.catch(err => console.error(err))
		}

		safeCb(callback)(this.currentUser);
		return Promise.resolve(this.currentUser);
	}

	/**
	 * Gets all available info on a user
	 *
	 * @return {Object}
	 */
	getCurrentUserSync() {
		return this.currentUser;
	}

	/**
	 * Checks if user is logged in
	 * @param {function} [callback]
	 * @returns {Promise}
	 */
	isLoggedIn(callback?) {
		return this.getCurrentUser()
			.then(() => {
				let is = !!this.currentUser._id;
				safeCb(callback)(is);
				return is;
			});
	}

	/**
	 * Checks if user is logged in
	 * @returns {Boolean}
	 */
	isLoggedInSync() {
		return !!this.currentUser._id;
	}

	/**
	 * Check if a user is an admin
	 *
	 * @param  {Function|*} [callback] - optional, function(is)
	 * @return {Promise}
	 */
	isAdmin(callback?) {
		return this.getCurrentUser().then(user => {
			var is = user.role === 'admin';
			safeCb(callback)(is);
			return is;
		});
	}

	isAdminSync() {
		return this.currentUser.role === 'admin';
	}

	/**
	 * Get auth token
	 *
	 * @return {String} - a token string used for authenticating
	 */
	getToken() {
		return this.cookieService.get('id_token');
	}
}
