import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
	selector: 'navbar',
	template: require('./navbar.html'),
	styles: [require('./navbar.css')]
})
export class NavbarComponent {
	isCollapsed = true;
	menu = [{
		title: 'Главная',
		'link': '/home',
	}];
	Router;
	isAdmin;
	isLoggedIn;
	currentUser = {};
	AuthService;

	static parameters = [AuthService, Router];
	constructor(private authService: AuthService, private router: Router) {
		this.AuthService = authService;

		this.Router = router;

		this.reset();

		this.AuthService.currentUserChanged.subscribe(user => {
			this.currentUser = user;
			this.reset();
		});
	}

	reset() {
		this.AuthService.isLoggedIn().then(is => {
			this.isLoggedIn = is;
		}).catch(err => this.Router.navigateByUrl('/login'));

		this.AuthService.isAdmin().then(is => {
			this.isAdmin = is;
		})
		.catch(err => this.Router.navigateByUrl('/login'));

		this.AuthService.getCurrentUser().then(user => {
			this.currentUser = user;
		})
		.catch(err => this.Router.navigateByUrl('/login'));
	}

	logout() {
		return this.AuthService.logout().then(() => {
			this.Router.navigateByUrl('/login');
			this.reset();
		});
	}}
