import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeType } from '../../../../components/resource/node.service';
import { Observable } from 'rxjs';
import { observe, generate } from 'fast-json-patch';

import { map, filter, tap, switchMap, catchError, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

const SEARCH_ADDRESS_URL = 'https://address.dom38.ru/api/houses/search';
const FIAS_ADDRESS_URL = '/api/nodes/fias/';
const PARAMS = new HttpParams({
	fromObject: {},
});

type House = {
  _id: number;
  name: string;
  full_address: string;
  fias_object_id: number;
}

@Component({
  template: require('./device.html'),
})
export class DeviceComponent {
  private node: NodeType;  
  private observer;
  private subscriber;
  typeahead: Observable<any>;
  typeaheadModel;

  static parameters = [HttpClient, NgbActiveModal];
  constructor(private http: HttpClient, private activeModal: NgbActiveModal) {
    
  }

  typeaheadOnSelect(event) {
    this.node.fias_id = event.item.fias_object_id;
  }

  onSave(node: NodeType): Observable<NodeType> {
    this.node = node;
    this.observer = observe(this.node);

    if(node.fias_id){
      this.http.get<House>(FIAS_ADDRESS_URL + node.fias_id)
        .subscribe(house => {
          this.typeaheadModel = house.full_address;
        });
    }

    this.typeahead = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.typeaheadModel);
    }).pipe(mergeMap((token: string) => {  

      return this.http
        .get<[House]>(SEARCH_ADDRESS_URL, { params: PARAMS.set('query', token) })
        .pipe(map(houses => houses.filter((item) => !!item.fias_object_id)));
    }));

    return new Observable(subscriber => {
      this.subscriber = subscriber;
    });
  }

  save(form) {
    if(form.invalid) return;

    this.subscriber.next(generate(this.observer));
  }
}
