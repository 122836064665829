// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type UplinkType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    deveui?: string;
    devaddr?: string;
    port?: number;
    datetime?: Date;
    data?: string;
};

@Injectable()
export class UplinkService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(params?): Observable<UplinkType[]> {
        return this.http.get('/api/uplinks/', {params: params}) as Observable<UplinkType[]>;
    }
    get(uplink: UplinkType): Observable<UplinkType> {
        return this.http.get(`/api/uplinks/${uplink.id || uplink._id}`) as Observable<UplinkType>;
    }
    create(uplink: UplinkType) {
        return this.http.post('/api/uplinks/', uplink);
    }
    update(uplink: UplinkType, path) {
        return this.http.patch('/api/uplinks/' + uplink._id, path) as Observable<UplinkType>;
    }
    remove(uplink) {
        return this.http.delete(`/api/uplinks/${uplink.id || uplink._id}`)
            .pipe(map(() => uplink));
    }
}
