import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AuthGuard } from '../../components/auth/auth-guard.service';
import { AuthModule } from '../../components/auth/auth.module';
import { AdminComponent } from './admin.component';
import { UserComponent } from './user/user.component';
import { DeviceComponent } from './device/device.component';
import { CallbackPipe, PaginationPipe } from '../../components/util';
import { AlarmFilterPipe } from '../../components/pipes/alarm-filter';
import { OrderByPipe } from '../../components/pipes/order-by';

import { DeviceComponent as DeviceFormComponent } from './device/form/device.component';
import { UploadComponent as DeviceUploadComponent } from './device/upload/upload.component';
import { UserComponent as UserFormComponent } from './user/form/user.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { ConfirmComponent } from '../../components/confirm/confirm.component';

const adminRoutes: Routes = [{
	path: 'admin',
	component: AdminComponent,
	canActivate: [AuthGuard],
}, {
	path: 'admin/users',
	component: UserComponent,
	canActivate: [AuthGuard],
}, {
	path: 'admin/devices',
	component: DeviceComponent,
	canActivate: [AuthGuard],
}];

@NgModule({
	imports: [
		AuthModule,
		BrowserModule,
		RouterModule.forChild(adminRoutes),
		TypeaheadModule.forRoot(),
		FormsModule,
		NgbModule,
	],
	providers: [
		OrderByPipe
	],
	declarations: [
		AdminComponent,
		UserComponent,
		DeviceComponent,

		DeviceFormComponent,
		DeviceUploadComponent,
		UserFormComponent,

		PaginationComponent,
		ConfirmComponent,


		CallbackPipe,
		PaginationPipe,
		AlarmFilterPipe,
		OrderByPipe
	],
	exports: [
		AdminComponent,
		UserComponent,
		DeviceComponent,
		
		PaginationComponent,
		ConfirmComponent,


		CallbackPipe,
		PaginationPipe,
		AlarmFilterPipe,
		OrderByPipe
	],

	entryComponents: [
		DeviceFormComponent,
		DeviceUploadComponent,
		UserFormComponent,
		ConfirmComponent,
	]
})
export class AdminModule {}
