import {Pipe, PipeTransform } from '@angular/core';
import { NodeType } from '../resource/node.service';
import { AlertType } from '../resource/alert.service';

@Pipe({name: 'alarmFilter', pure: false})
export class AlarmFilterPipe implements PipeTransform {
  transform(items: AlertType[], allowed: NodeType[]): AlertType[] {
    return items.filter(alert => allowed.map(node => node.deveui).indexOf(alert.deveui) != -1)
  }
}
