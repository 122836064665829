import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserType } from '../../../../components/auth/user.service';
import { Observable } from 'rxjs';
import { observe, generate } from 'fast-json-patch';
import { userRoles } from '../../../app.constants';

@Component({
  template: require('./user.html'),
})
export class UserComponent {
  private user: UserType;  
  private observer;
  private subscriber;
  private roles;

  static parameters = [NgbActiveModal];
  constructor(private activeModal: NgbActiveModal) {
    this.roles = userRoles || [];
  }

  onSave(user: UserType): Observable<UserType> {
    this.user = user;
    this.observer = observe(this.user);

    return new Observable(subscriber => {
      this.subscriber = subscriber;
    });
  }

  save(form) {
    if(form.invalid) return;

    this.subscriber.next(generate(this.observer));
  }
}
