import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SocketService } from '../../components/socket/socket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceComponent as DeviceFormComponent } from './device/device.component';
import { ArchiveComponent } from '../../components/archive/archive.component';
import { InitialComponent } from '../../components/initial/initial.component';
import { UserService } from '../../components/auth/user.service';
import { DeviceService, DeviceType } from '../../components/resource/device.service';
import { DeviceTypeService } from '../../components/resource/device-type.service';
import { UplinkService } from '../../components/resource/uplink.service';
import { ValueService } from '../../components/resource/value.service';
import { StatService } from '../../components/resource/stat.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import Quagga from 'quagga';

@Component({
  selector: 'main',
  template: require('./main.html'),
  styles: [require('./main.css')],
})
export class MainComponent implements OnInit, OnDestroy {
  SocketService;
  DeviceService;
  DeviceTypeService;
  ValueService;
  ModalService;
  ReCaptchaV3Service;

  devices = [];
  types = [];
  newDevice:DeviceType = {
    serial: '',
    deveui: '',
    name: '',
    token: ''
  };
  scanAllowed = false;

  trustAsHtml: Function;

  static parameters = [SocketService, NgbModal, DeviceService, DeviceTypeService, ValueService, ReCaptchaV3Service];
  constructor(
      private socketService: SocketService, 
      private modal: NgbModal, 
      private deviceService: DeviceService, 
      private deviceTypeService: DeviceTypeService, 
      private valueService: ValueService, 
      private reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.SocketService      = socketService;
    this.DeviceService      = deviceService;
    this.DeviceTypeService  = deviceTypeService;
    this.ValueService       = valueService;
    this.ModalService       = modal;
    this.ReCaptchaV3Service = reCaptchaV3Service;

    //this.trustAsHtml   = $sce.trustAsHtml;

    // let navi: any;
    // navi = navigator;

    // if(navi.mediaDevices && typeof navi.mediaDevices.getUserMedia === 'function'){
    //   // safely access `navigator.mediaDevices.getUserMedia`
    //   this.scanAllowed = true;
    // }
  }

  ngOnInit() {
    this.DeviceService.query().subscribe(devices => {
      this.devices = devices;
      this.SocketService.syncUpdates('device', this.devices);
    });

    this.DeviceTypeService.query().subscribe(types => {
      this.types = types;
      this.SocketService.syncUpdates('device-type', this.types);
    })
  }

  ngOnDestroy() {
    this.SocketService.unsyncUpdates('device');
    this.SocketService.unsyncUpdates('device-type');
  }

  initQuagga(dialog) {
    let el: any;
    el = document.querySelector('#interactive');
    console.log(el, el.offsetWidth, el.offsetHeight);
    Quagga.init({
      inputStream: {
        constraints: {
          width: { ideal: 4096 },
          height: { ideal: 2160 } 
        //   // some jind a bug
        //   height: el.offsetWidth || 300,
        //   width: el.offsetHeight | 600,
        // //   facingMode: 'environment' // restrict camera type
        },
        area: { // defines rectangle of the detection
          top: '40%',    // top offset
          right: '0%',  // right offset
          left: '0%',   // left offset
          bottom: '40%'  // bottom offset
        },
        name : "Live",
        type : "LiveStream",
        target: document.querySelector('#interactive')
      },
      debug: {
        drawBoundingBox: true,
        showFrequency: true,
        drawScanline: true,
        showPattern: true
      },
      decoder: {
        readers: ['ean_reader'] // restrict code types
      },
    },
    (err) => {
      if (err) {
        let errorMessage = `QuaggaJS could not be initialized, err: ${err}`;
        console.error(errorMessage);

        document.querySelector('#interactive').innerHTML = errorMessage;
      } else {

        let video:any;
        video = el.querySelector("canvas");

        el.style.height = video.offsetHeight;
        console.log({height: video.offsetHeight});

        console.log("Start scaning...");
        Quagga.start();
        Quagga.onDetected((res) => {
          //window.alert(`code: ${res.codeResult.code}`);

          this.newDevice.deveui = res.codeResult.code;
          dialog.dismiss()
        })
      }
    });
  }

  openScanner() {
    // let dialog = this.openModal({
    //   modal: {
    //     dismissable: true,
    //     title: 'Чтение штрихкода',
    //     html: this.trustAsHtml('<div id="interactive" class="cam-viewport"><!-- QuaggaJS will populate this element --></div>'),
    //     buttons: [{
    //       classes: 'btn-default',
    //       text: 'Закрыть',
    //       click: function(e) {
    //         dialog.dismiss(e);
    //       }
    //     }]
    //   }
    // });

    // dialog.rendered.then(() => this.initQuagga(dialog));
    // dialog.closed.then(() => {
    //   console.log("Stop scaning...");
    //   Quagga.stop()
    // });
  }

  openArchive(device: DeviceType) {
    let dialog = this.ModalService.open(ArchiveComponent, {size: 'lg', animation: true});
    dialog.componentInstance.load(device.deveui);
  }

  createDevice() {
    let dialog     = this.ModalService.open(DeviceFormComponent);
    let observable = dialog.componentInstance.onSave(this.newDevice);
    
    observable.subscribe(path => {

      this.ReCaptchaV3Service.execute('createDevice')
        .subscribe((token) => {this.saveDevice(dialog, token)}, err => console.error(err));
    });

    dialog.result.then(() => {
      console.log(observable);
      // after dismiss
      //observable.unsubscribe();
    });
  }

  saveDevice(dialog, response: string) {
    this.newDevice.token = response;
    return this.DeviceService.create(this.newDevice)
      .subscribe(device => {
        this.devices.push(device);
        this.newDevice = {};
        dialog.close();
      })
  }

  trackByFn(index, device) {
    return device._id;
  }
}
