import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../components/resource/event.service';
import { SocketService } from '../../components/socket/socket.service';
 

@Component({
  selector: 'event',
  template: require('./event.html'),
})
export class EventComponent {
  EventService;
  SocketService;
  deveui;
  events = [];

  static parameters = [EventService, SocketService, NgbActiveModal];
  constructor(private eventService: EventService, private socket: SocketService, private activeModal: NgbActiveModal) {
    this.EventService  = eventService;
    this.SocketService = socket;
  }

  load(deveui: string) {
    this.deveui = deveui;

    this.EventService.query({deveui: this.deveui})
      .subscribe(events => {
        this.events = events;
        this.SocketService.syncUpdates('Event', this.events);
      });
  }

  ngOnDestroy(): void {
    this.SocketService.unsyncUpdates('Event');
  }

}
