// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type NodeType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    deveui?: string;
    serial?: string;
    profile?: string;
    appkey?: string;
    appskey?: string;
    nwkskey?: string;
    devaddr?: string;
    offset_time: number;
    offset_value: number;
    request_current_time: boolean;
    dtime_last_value?: string;
    dtime_last_request?: string;
    dtime_last_event?: string;
    disabled: boolean;
    fias_id?: number;
    flat?: number;
    address?: string;

    batVoltage?: number;
    LC12?: number;
    LC13?: number;
    LC14?: number;
    LC15?: number;
    LC16?: number;
    dataCoeficient?: number;
    timePeriod?: number;
};

@Injectable()
export class NodeService {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    query(params?): Observable<NodeType[]> {
        return this.http.get('/api/nodes/', {params: params}) as Observable<NodeType[]>;
    }
    get(node: NodeType): Observable<NodeType> {
        return this.http.get(`/api/nodes/${node.id || node._id}`) as Observable<NodeType>;
    }
    create(node: NodeType) {
        return this.http.post('/api/nodes/', node);
    }
    sendCommand(node: NodeType, commandId: string, command: string, sequenceId?: number) {
        return this.http.post(`/api/nodes/send-command/${node.id || node._id}`, {
            commandId: commandId,
            sequenceId: sequenceId || 0,
            command: command
        });
    }
    update(node: NodeType, path) {
        return this.http.patch('/api/nodes/' + node._id, path) as Observable<NodeType>;
    }
    remove(node) {
        return this.http.delete(`/api/nodes/${node.id || node._id}`)
            .pipe(map(() => node));
    }
}
